<template>
  <div class="page-container">
    <div class="flex flex-center flex-dr">
      <div class="experiment-name">{{ packageInfo.experiment.name }}</div>
      <div class="versionName">
        {{
          packageInfo.experimentPackage.branchType
        }}版-{{ enum_versionManager.versionType[packageInfo.experimentPackage.versionType] }}
      </div>
      <div class="infos">
        <el-form>
          <el-form-item label="版本号：" style="margin-bottom: 10px">{{
              packageInfo.experimentPackage.versionNumber
            }}
          </el-form-item>
          <el-form-item label="版本名称：" style="margin-bottom: 10px">{{
              packageInfo.experimentPackage.name
            }}
          </el-form-item>
          <el-form-item label="更新时间：" style="margin-bottom: 10px">
            {{ date_format(packageInfo.experimentPackage.createTime, "yyyy-MM-dd HH:mm:ss") }}
          </el-form-item>
          <el-form-item label="更新说明：" style="margin-bottom: 10px">
          </el-form-item>
          <div v-html="packageInfo.experimentPackage.description" style="width: 100%;margin-bottom:30px;"></div>
          <el-form-item label="下载链接：" style="margin-bottom: 30px"
                        v-if="packageInfo.experimentPackage.allowDownload&&allowDownload">
            <div v-if="!packageInfo.url">
              <el-input v-model="packageInfo.password" placeholder="输入下载密码"
                        style="width: 200px"></el-input>
              <el-button @click="clickGetUrlBtn">获取链接</el-button>
            </div>
            <div v-if="packageInfo.url">
              {{ packageInfo.url }}
              <el-button size="small" style="margin-left: 30px" @click="e=>clickCopyUrlBtn(e,packageInfo.url)">复制链接
              </el-button>
              <div></div>
              <el-alert
                  title="请复制链接到浏览器地址栏打开下载，或使用迅雷等下载工具下载"
                  type="success">
              </el-alert>
            </div>
          </el-form-item>
          <el-alert
              v-if="!packageInfo.experimentPackage.allowDownload&&allowDownload"
              title="此版本包暂时不允许下载!"
              type="error">
          </el-alert>
          <el-alert
              v-if="!allowDownload"
              title="此版本包暂时不允许下载!"
              type="error">
          </el-alert>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import {ConfigModel} from "@/model/erp/ConfigModel";
import {CONFIG_NAME_VERSION_MANAGER} from "@/model/ConfigModel";
import {ExperimentPackageModel} from "@/model/versionManager/ExperimentPackageModel";
import versionManager from "@/enums/versionManager";
import {date_format, downloadFileNew} from "@/utils/common";
import {msg_err} from "@/utils/ele_component";
import clip from "@/utils/clipboard";

export default {
  name: "experimentPackageDownload",
  data() {
    return {
      date_format: date_format,
      enum_versionManager: versionManager,
      experimentPackageId: this.$route.query["experimentPackageId"],
      fileIndex: this.$route.query["fileIndex"],
      allowDownload: true,
      packageInfo: {
        experiment: {},
        experimentPackage: {}
      }
    }
  },
  async mounted() {
    // 获取总体配置
    this.allowDownload = await ConfigModel.getConfig(CONFIG_NAME_VERSION_MANAGER, "allowDownload") === 'true'
    // 获取该包信息
    let experimentResult = await ExperimentPackageModel.getOne({
      experimentPackageId: this.experimentPackageId
    })
    this.packageInfo = experimentResult

    document.title = "实验包下载"
    let indexManage = await ConfigModel.getIndexConfig();
    this.infoManage = indexManage.infoManage
    this.indexExperimentManage = indexManage.experimentManage
    $("#header-container img.logo").attr("src", this.infoManage.logos.pc_logo_product) // 改变logo
    $("#header-container .product-navs .title").css("font-weight", "normal").css("opacity", "0.6")
  },
  methods: {
    clickDownloadBtn(){
      downloadFileNew(this.packageInfo.url)
    },
    clickCopyUrlBtn(e, url) {
      clip(url, e);
    },
    async clickGetUrlBtn() {
      if (!this.packageInfo.password) {
        msg_err("请输入下载密码")
        return
      }
      let result = await ExperimentPackageModel.getDownUrlByPassword({
        experimentPackageId: this.experimentPackageId,
        password: this.packageInfo.password,
        fileIndex: this.fileIndex
      })
      if (result) {
        this.$set(this.packageInfo, "url", result)
      }
    }
  }
}
</script>
<style>
#header-container .right span {
  color: #000000;
}

#header-container img.white {
  display: none;
}
.footer-container{

}
</style>
<style scoped lang="less">
.page-container {
  padding-top: 150px;
  width: 1000px;
  margin: 0 auto;
}

.experiment-name {
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 10px;
}

.versionName {
  font-size: 21px;
}

.infos {
  margin-top: 20px;
  margin-bottom: 150px;
  width: 700px;
}
</style>
