import {request_async} from "@/utils/requestAsync";
import {API_URL_VERSION_MANAGER} from "@/model/ConfigModel";

// 获取一个
export async function getOne(data) {
  return request_async(API_URL_VERSION_MANAGER + "/v1/experimentPackage/officialPage", "get", data);
}

// 新增下载日志
export async function addDownloadLog(data) {
  return request_async(API_URL_VERSION_MANAGER + "/v1/experimentPackage/addDownloadLog", "post_body", data);
}

// 通过密码获取下载链接
export async function getDownUrlByPassword(data) {
  return request_async(API_URL_VERSION_MANAGER + "/v1/experimentPackage/getDownUrlByPassword", "get", data);
}
