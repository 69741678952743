// 通用枚举
export default {
  // 版本类型
  versionType: {
    "lts": "长期支持版(LTS)",
    "stable": "稳定版(Stable)",
    "test": "测试版(Test)",
    "dev": "开发版(Dev)",
  }
}
