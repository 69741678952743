
import {addDownloadLog,getOne,getDownUrlByPassword} from "@/api/versionManager/ExperimentPackageApi";

/**
 * ExperimentPackage model
 */
class ExperimentPackageModel {
    // 获取一个
    static async getOne(id) {
        let [res] = await getOne(id)
        if (res.code === "000000") {
            return res.data
        } else {
            return false
        }
    }

    // 通过密码获取下载链接
    static async getDownUrlByPassword(data) {
        let [res] = await getDownUrlByPassword(data)
        if (res.code === "000000") {
            return res.data
        } else {
            return false
        }
    }

    // 新增下载日志
    static async addDownloadLog(entity) {
        let [data] = await addDownloadLog(entity);
        return data;
    }
}

export {ExperimentPackageModel}
